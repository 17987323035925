import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import Moment from 'moment';
import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

import * as _ from 'lodash';

import { loadReCaptcha } from 'react-recaptcha-v3';

import {
  Box,
  StepLabel,
  Step,
  Stepper,
  StepContent,
  Grid,
  Container,
  Divider,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import {
  getPublicShopDetails,
  getPublicShopAppointmentsV2,
  bookAppointmentV2,
  getPublicShopAvailability,
} from '../actions';
import { isValidEmail, isBrowser, ccyFormat } from '../utils';

import BaseHOC from './BaseHOC';
import SEO from './seo';

import Contact from './Contact';
import OpeningHours from './OpeningHours';
import BookingFooter from './BookingFooter';
import BookingSuccess from './BookingSuccess';
import PrimaryButton from './PrimaryButton';

import ServiceSelectorStep from './BookingSteps/ServiceSelectorStep';
import BarberSelectorStep from './BookingSteps/BarberSelectorStep';
import AppointmentSelectorStep from './BookingSteps/AppointmentSelectorStep';
import CustomerDetailsStep from './BookingSteps/CustomerDetailsStep';
import ExtrasSelectorStep from './BookingSteps/ExtrasSelectorStep';

const momentRange = extendMoment(Moment);

const TransparentStepper = styled(Stepper)`
  && {
    background-color: inherit;
  }
`;

const PaddedGrid = styled(Grid)`
  && {
    padding: 2rem 0rem;
  }
`;

const BookService = ({ enqueueSnackbar, shopDetails, serviceBaseId }) => {
  const { formatMessage: f, locale } = useIntl();
  const dispatch = useDispatch();
  const publicShopAvailabilities = useSelector(
    (state) => state.publicShopAvailabilities,
  );
  const publicShopAppointments = useSelector(
    (state) => state.publicShopAppointments,
  );
  const publicShopDetails = useSelector((state) => state.publicShopDetails);
  const apiRequest = useSelector((state) => state.apiRequest);

  const [activeStep, setActiveStep] = useState(serviceBaseId ? 1 : 0);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedShop, setSelectedShop] = useState(null);
  const [barberId, setBarberId] = useState('');
  const [service, setService] = useState('');
  const [
    selectedExtraServiceBaseIds,
    setSelectedExtraServiceBaseIds,
  ] = useState([]);
  const [selectedExtraBarberIds, setSelectedExtraBarberIds] = useState([]);
  const [timeslot, setTimeslot] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientNameError, setClientNameError] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientEmailError, setClientEmailError] = useState('');
  const [clientMobile, setClientMobile] = useState('');
  const [clientMobileError, setClientMobileError] = useState('');
  const [consentAccepted, setConsentAccepted] = useState(false);
  const [saveDetails, setSaveDetails] = useState(false);
  const [consentAcceptedError, setConsentAcceptedError] = useState('');
  const [nonFieldError, setNonFieldError] = useState('');
  const [token, setToken] = useState('');
  const [loader, setLoader] = useState(false);
  const [policyVersion, setPolicyVersion] = useState('');
  const [succesfulBooking, setSuccesfulBooking] = useState(false);
  const [messengerOptIn, setMessengerOptIn] = useState(false);
  const [userRef, setUserRef] = useState(
    Math.floor(Math.random() * 10000000000000 + 1),
  );
  const stepRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];

  const getSelectedBarber = (barberIdArg) =>
    shopDetails.barbers.find(
      (x) => x.id.toString() === barberIdArg.toString(),
    ) || {
      display_name: f({ id: 'bookingBookNoPreference' }),
    };

  useEffect(() => {
    if (
      activeStep > 0 &&
      stepRefs[activeStep].current &&
      !shopDetails.warning_message
    ) {
      window.scrollTo({
        left: 0,
        top: stepRefs[activeStep - 1].current.offsetTop - 8,
        behavior: 'smooth',
      });
    }
  }, [activeStep]);

  useEffect(() => {
    if (isBrowser) {
      const details =
        JSON.parse(localStorage.getItem('customerDetails')) || null;
      if (details) {
        setSaveDetails(true);
        setClientName(details.clientName);
        setClientEmail(details.clientEmail);
        setClientMobile(details.clientMobile);
      }
    }
  }, [isBrowser]);

  useEffect(() => {
    if (isBrowser && shopDetails && shopDetails.shopId) {
      dispatch(getPublicShopDetails(shopDetails.shopId));
    }
  }, [isBrowser, shopDetails]);

  useEffect(() => {
    if (succesfulBooking) {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
  }, [succesfulBooking]);

  const getServiceDurationForBarber = (barber, serviceBaseIdArg) => {
    const selectedService = shopDetails?.services?.find(
      (x) => x.base_id === serviceBaseIdArg,
    );
    if (barber) {
      const levelId = barber.service_levels?.find(
        (x) => x.service_base_id === serviceBaseIdArg,
      )?.level?.id;

      if (selectedService && levelId) {
        return selectedService.service_levels.find(
          (x) => x.level.id === levelId,
        )?.duration;
      }
    }
    return selectedService.duration;
  };

  const getServicePriceForBarber = (barber, serviceBaseIdArg) => {
    const selectedService = shopDetails?.services?.find(
      (x) => x.base_id === serviceBaseIdArg,
    );
    if (barber.id) {
      const levelId = barber.service_levels?.find(
        (x) => x.service_base_id === serviceBaseIdArg,
      )?.level?.id;

      if (selectedService && levelId) {
        return ccyFormat(
          selectedService.service_levels.find((x) => x.level.id === levelId)
            ?.price,
          shopDetails.country.currency,
        );
      }
      return ccyFormat(selectedService.price, shopDetails.country.currency);
    }

    if (
      selectedService.service_levels.length > 0 ||
      !selectedService.fix_price
    ) {
      return f(
        { id: 'barberMinimumServicePriceFormat' },
        {
          price: ccyFormat(selectedService.price, shopDetails.country.currency),
        },
      );
    }

    return ccyFormat(selectedService.price, shopDetails.country.currency);
  };

  const getStepLabels = () => {
    const labels = [
      <>
        <Box>{f({ id: 'bookingBookServiceStepTitle' })}</Box>
        {service ? (
          <Box fontWeight="fontWeightBold">
            <FormattedMessage
              id="bookingBookServiceFormat"
              values={{
                service: service.title,
                duration: getServiceDurationForBarber(
                  getSelectedBarber(barberId),
                  (service && service.base_id) || serviceBaseId,
                ),
                price: getServicePriceForBarber(
                  getSelectedBarber(barberId),
                  (service && service.base_id) || serviceBaseId,
                ),
              }}
            />
          </Box>
        ) : null}
      </>,
      <>
        <Box>{f({ id: 'bookingBookBarberStepTitle' })}</Box>
        {barberId && getSelectedBarber(barberId) ? (
          <Box fontWeight="fontWeightBold">
            {getSelectedBarber(barberId).display_name}
          </Box>
        ) : null}
      </>,
      <>
        <Box>{f({ id: 'bookingBookDateStepTitle' })}</Box>
        {selectedDate && timeslot ? (
          <Box fontWeight="fontWeightBold">
            {`${moment(selectedDate)
              .locale(locale)
              .format('YYYY-MM-DD')} ${timeslot}`}
          </Box>
        ) : null}
      </>,
      <Box>{f({ id: 'bookingBookCustomerStepTitle' })}</Box>,
    ];

    if (
      service?.next_services_base_ids?.length ||
      service?.previous_services_base_ids?.length
    ) {
      labels.splice(
        2,
        0,
        <>
          <Box>{f({ id: 'bookingBookExtrasStepTitle' })}</Box>
          {selectedExtraServiceBaseIds.length > 0 && (
            <Box fontWeight="fontWeightBold">
              {selectedExtraServiceBaseIds.map((x, i) => {
                const serviceFound = shopDetails?.services?.find(
                  (y) => y.base_id === x,
                );
                if (serviceFound) {
                  return (
                    <>
                      <FormattedMessage
                        id="bookingBookServiceFormat"
                        values={{
                          service: serviceFound.title,
                          duration: getServiceDurationForBarber(
                            selectedExtraBarberIds[i],
                            serviceFound.base_id,
                          ),
                          price: getServicePriceForBarber(
                            selectedExtraBarberIds[i],
                            serviceFound.base_id,
                          ),
                        }}
                      />
                      <br />
                    </>
                  );
                }
              })}
            </Box>
          )}
        </>,
      );
    }

    return labels;
  };

  const getBackLabels = () => {
    const labels = [
      null,
      f({ id: 'bookingBookBackService' }),
      f({ id: 'bookingBookBackBarber' }),
      f({ id: 'bookingBookBackDate' }),
    ];

    if (
      service?.next_services_base_ids?.length ||
      service?.previous_services_base_ids?.length
    ) {
      labels.splice(3, 0, <Box>{f({ id: 'bookingBookBackExtras' })}</Box>);
    }

    return labels;
  };

  const setServiceFromId = (id) => {
    const selectedService = shopDetails.services.find(
      (x) => x.base_id === parseInt(id, 10),
    );

    setService(selectedService);
  };

  useEffect(() => {
    loadReCaptcha(process.env.GATSBY_RECAPTCHA_KEY);
    setServiceFromId(serviceBaseId);
    setPolicyVersion(shopDetails.policy && shopDetails.policy.uuid);
  }, [serviceBaseId, shopDetails]);

  useEffect(() => {
    if (barberId && service && selectedDate && selectedShop) {
      dispatch(
        getPublicShopAppointmentsV2({
          shopId: selectedShop?.id,
          barberIds: [barberId, ...selectedExtraBarberIds].map((x) =>
            x === -1 ? '' : x,
          ),
          serviceIds: [
            service.id,
            ...selectedExtraServiceBaseIds.map(
              (x) => shopDetails?.services?.find((y) => y.base_id === x)?.id,
            ),
          ],
          bookingDate: moment(selectedDate).format(moment.HTML5_FMT.DATE),
          enqueueSnackbar,
        }),
      );
    }
  }, [
    selectedShop,
    service,
    barberId,
    selectedDate,
    selectedExtraServiceBaseIds,
    selectedExtraBarberIds,
  ]);

  useEffect(() => {
    if (barberId) {
      dispatch(
        getPublicShopAvailability({
          shopId: shopDetails.shopId,
          barberId: barberId === -1 ? null : barberId,
          startDate: moment()
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
          endDate: moment()
            .add(shopDetails.maximum_booking_wait_time, 'days')
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
          enqueueSnackbar,
        }),
      );
    }
  }, [shopDetails, barberId]);

  useEffect(() => {
    if (apiRequest.inProgress) {
      setLoader(true);
    } else {
      setLoader(false);
      if (apiRequest.error) {
        setActiveStep(3);
      } else if (activeStep === null) {
        if (shopDetails.success_redirect_url && window) {
          window.location = shopDetails.success_redirect_url;
        } else {
          setSuccesfulBooking(true);
        }
      }
    }

    setClientEmailError('');
    setClientMobileError('');

    if (apiRequest.error) {
      apiRequest.error
        .map((x) => JSON.parse(x))
        .forEach((x) => {
          if (x.clientData) {
            Object.keys(x.clientData).forEach((y) => {
              if (y === 'clientEmail') setClientEmailError(x.clientData[y]);
              if (y === 'clientMobile') setClientMobileError(x.clientData[y]);
            });
          } else if (x.non_field_errors) {
            setNonFieldError(x.non_field_errors.join(', '));
          } else {
            console.log(x);
            setNonFieldError(x);
          }
        });
    }
  }, [apiRequest]);

  useEffect(() => {
    if (publicShopAvailabilities && selectedDate) {
      const availableShops = publicShopAvailabilities
        .filter((x) => x.date === selectedDate.format(moment.HTML5_FMT.DATE))
        .map((x) => x.shop);

      const currentShop = availableShops.filter(
        (x) => x.id === shopDetails.shopId,
      )[0];
      if (!currentShop) {
        setSelectedShop(
          publicShopAvailabilities.filter(
            (x) => x.date === selectedDate.format(moment.HTML5_FMT.DATE),
          )[0]?.shop,
        );
      } else {
        setSelectedShop(currentShop);
      }
    }
  }, [publicShopAvailabilities, selectedDate]);

  const handleMessengerCheckboxEvent = useCallback((e) => {
    if (e.event === 'checkbox') {
      setMessengerOptIn(e.state === 'checked');
    }
  }, []);

  const submitData = () => {
    let error = false;

    if (!consentAccepted) {
      setConsentAcceptedError(f({ id: 'bookingErrorTCs' }));
      error = true;
    } else {
      setConsentAcceptedError('');
    }

    if (!clientName) {
      setClientNameError(f({ id: 'bookingErrorName' }));
      error = true;
    } else {
      setClientNameError('');
    }

    if (!clientEmail) {
      setClientEmailError(f({ id: 'bookingErrorEmail' }));
      error = true;
    } else if (!isValidEmail(clientEmail)) {
      setClientEmailError(f({ id: 'bookingErrorInvalidEmail' }));
      error = true;
    } else {
      setClientEmailError('');
    }

    if (!clientMobile) {
      setClientMobileError(f({ id: 'bookingErrorMobile' }));
      error = true;
    } else {
      setClientMobileError('');
    }

    if (error) return;

    const bookedTimestamp = moment
      .tz(
        `${moment(selectedDate).format('YYYY-MM-DD')} ${timeslot}`,
        shopDetails.timezone,
      )
      .format();

    setActiveStep(null);

    if (saveDetails) {
      localStorage.setItem(
        'customerDetails',
        JSON.stringify({
          clientName,
          clientEmail,
          clientMobile,
        }),
      );
    }

    if (window.confirmOptIn) {
      window.confirmOptIn();
    }

    dispatch(
      bookAppointmentV2(
        //  - TODO: override with other shop ID depending on availability
        selectedShop?.id || shopDetails.shopId,
        {
          barberIds: [barberId, ...selectedExtraBarberIds].map((x) =>
            x === -1 ? '' : x,
          ),
          // - TODO: override with other shop's service ID depending on availability
          serviceBaseIds: [
            service.base_id,
            ...selectedExtraServiceBaseIds.map(
              (x) =>
                shopDetails?.services?.find((y) => y.base_id === x)?.base_id,
            ),
          ],
          token,
          bookedTimestamp,
          clientData: { clientName, clientEmail, clientMobile },
          consentAccepted,
          policyVersion,
          messengerOptIn,
          userRef,
        },
        enqueueSnackbar,
        f,
      ),
    );

    setUserRef(Math.floor(Math.random() * 10000000000000 + 1));
  };

  const stepLabels = getStepLabels();

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const barbers =
    (service &&
      (publicShopDetails?.barbers
        ?.filter((x) => !x.disable_booking)
        .filter(
          (x) =>
            !x.exclude_service_ids ||
            x.exclude_service_ids.indexOf(service.base_id) === -1,
        ) ||
        shopDetails.barbers
          .filter((x) => !x.disable_booking)
          .filter(
            (x) =>
              !x.exclude_service_ids ||
              x.exclude_service_ids.indexOf(service.base_id) === -1,
          ))) ||
    [];

  const getStepContent = (step) => {
    const stepSelectService = (
      <ServiceSelectorStep
        value={service && service.base_id}
        onChange={(event) => {
          setServiceFromId(event.target.value);
          handleNext();
        }}
        options={shopDetails.services}
        country={shopDetails.country}
        barber={barbers.find((x) => x.id === barberId)}
      />
    );
    const stepSelectExtras = (
      <ExtrasSelectorStep
        shopDetails={shopDetails}
        service={service}
        selectedExtraServiceBaseIds={selectedExtraServiceBaseIds}
        setSelectedExtraServiceBaseIds={setSelectedExtraServiceBaseIds}
        selectedExtraBarberIds={selectedExtraBarberIds}
        setSelectedExtraBarberIds={setSelectedExtraBarberIds}
        selectedBarberId={barberId}
      />
    );
    const stepSelectBarber = (
      <BarberSelectorStep
        value={barberId}
        onChange={(event) => {
          setBarberId(event.target.value);
          if (event.target.value === -1) {
            setSelectedShop(null);
          }
          handleNext();
        }}
        serviceBaseId={service && service.base_id}
        options={barbers}
      />
    );
    const stepSelectAppointments = publicShopAppointments && (
      <AppointmentSelectorStep
        closeDates={(shopDetails.close_dates || []).concat(
          Array.from(
            momentRange
              .range(
                moment(),
                moment().add(shopDetails.maximum_booking_wait_time, 'days'),
              )
              .by('days'),
          )
            .filter(
              (x) =>
                publicShopAvailabilities
                  .filter(
                    // Barber filter
                    (y) => barberId === -1 || barberId === y.employee,
                  )
                  .map((y) => y.date)
                  .indexOf(x.format(moment.HTML5_FMT.DATE)) === -1,
            )
            .map((x) => x.format(moment.HTML5_FMT.DATE)),
        )}
        maximumBookingWaitTime={shopDetails.maximum_booking_wait_time}
        appointments={publicShopAppointments.results}
        selectedShop={selectedShop}
        selectedDate={selectedDate}
        selectedTimeslot={timeslot}
        onDateChange={(value) => {
          setSelectedDate(value);
          setSelectedShop(
            publicShopAvailabilities.filter(
              (x) => x.date === value.format(moment.HTML5_FMT.DATE),
            )[0]?.shop,
          );
        }}
        onClick={(e) => {
          setTimeslot(e.target.innerText.trim());
          handleNext();
        }}
        shopDetails={shopDetails}
      />
    );
    const stepCustomerDetails = (
      <CustomerDetailsStep
        shopDetails={shopDetails}
        clientName={clientName}
        clientNameError={clientNameError}
        setClientName={setClientName}
        clientEmail={clientEmail}
        clientEmailError={clientEmailError}
        setClientEmail={setClientEmail}
        clientMobile={clientMobile}
        clientMobileError={clientMobileError}
        consentAccepted={consentAccepted}
        setConsentAccepted={setConsentAccepted}
        consentAcceptedError={consentAcceptedError}
        setClientMobile={setClientMobile}
        saveDetails={saveDetails}
        setSaveDetails={setSaveDetails}
        verifyCallback={(token) => setToken(token)}
        onMessengerCheckboxEvent={(e) => {
          handleMessengerCheckboxEvent(e);
        }}
        userRef={userRef}
        onClick={(e) => {
          submitData();
        }}
      />
    );

    if (
      service?.next_services_base_ids?.length ||
      service?.previous_services_base_ids?.length
    ) {
      switch (step) {
        case 0:
          return stepSelectService;
        case 1:
          return stepSelectBarber;
        case 2:
          return stepSelectExtras;
        case 3:
          return stepSelectAppointments;
        case 4:
          return stepCustomerDetails;
        default:
          return 'Unknown step';
      }
    } else {
      switch (step) {
        case 0:
          return stepSelectService;
        case 1:
          return stepSelectBarber;
        case 2:
          return stepSelectAppointments;
        case 3:
          return stepCustomerDetails;
        default:
          return 'Unknown step';
      }
    }
  };

  const canProgress = () => {
    return (
      (activeStep === 0 && service) ||
      (activeStep === 1 && barberId) ||
      (activeStep === 2 && timeslot) ||
      (activeStep === 2 &&
        (service?.next_services_base_ids?.length ||
          service?.previous_services_base_ids?.length)) ||
      (activeStep === 3 &&
        !(
          service?.next_services_base_ids?.length ||
          service?.previous_services_base_ids?.length
        )) ||
      (activeStep === 3 &&
        timeslot &&
        (service?.next_services_base_ids?.length ||
          service?.previous_services_base_ids?.length))
    );
  };

  const backLabels = getBackLabels();

  return (
    <>
      <SEO
        title={`${shopDetails.name} | ${f({ id: 'bookingDefaultSEOTitle' })}`}
        description={shopDetails.meta_description}
        thumbnailPreview={shopDetails.hero_image}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        minHeight="100vh"
      >
        <Box>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Grid item xs={12}>
              <Box
                textAlign="center"
                position="relative"
                mx="auto"
                pt={4}
                maxWidth="600px"
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h3" component="h1" gutterBottom>
                      <Box fontWeight={600}>{shopDetails.name}</Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" component="h2" gutterBottom>
                      <Box>
                        {(service && service.title) ||
                          f({ id: 'bookingDefaultSEOTitle' })}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <PaddedGrid item xs={12}>
              <Container maxWidth="sm">
                {shopDetails.warning_message && (
                  <Typography variant="h6" align="center" color="error">
                    <Box pb={2}>{shopDetails.warning_message}</Box>
                  </Typography>
                )}
                <Typography variant="h6" align="center">
                  {f({ id: 'bookingFollowSteps' })}
                </Typography>
                <TransparentStepper
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  activeStep={activeStep}
                  orientation="vertical"
                >
                  {stepLabels.map((label, index) => (
                    <Step key={`step-label-${index}`}>
                      <StepLabel
                        onClick={() => {
                          if (index < activeStep) {
                            setActiveStep(index);
                          }
                        }}
                        style={{
                          cursor: index < activeStep ? 'pointer' : 'initial',
                        }}
                      >
                        <div ref={stepRefs[index]}>{label}</div>
                      </StepLabel>
                      <StepContent style={{ paddingLeft: 0 }}>
                        <>
                          <Box display="flex" justifyContent="center" m={1}>
                            {getStepContent(index)}
                          </Box>
                          <Box display="flex" justifyContent="center" m={2}>
                            {activeStep === 0 || activeStep === null ? null : (
                              <Box m={1} ml={3}>
                                <PrimaryButton onClick={handleBack}>
                                  {backLabels[activeStep]}
                                </PrimaryButton>
                              </Box>
                            )}
                            {(!(
                              service?.next_services_base_ids?.length ||
                              service?.previous_services_base_ids?.length
                            ) &&
                              activeStep === 3) ||
                            ((service?.next_services_base_ids?.length ||
                              service?.previous_services_base_ids?.length) &&
                              activeStep === 4) ? null : (
                              <Box m={1}>
                                <PrimaryButton
                                  onClick={handleNext}
                                  disabled={!canProgress()}
                                >
                                  {f({ id: 'buttonOk' })}
                                </PrimaryButton>
                              </Box>
                            )}
                          </Box>
                        </>
                      </StepContent>
                    </Step>
                  ))}
                </TransparentStepper>
                <Box display="flex" justifyContent="center">
                  {loader ? <CircularProgress /> : null}
                </Box>
                {nonFieldError ? (
                  <Box display="flex" justifyContent="center" bgcolor="#ffcdd2">
                    <Typography variant="body2">{nonFieldError}</Typography>
                  </Box>
                ) : null}
              </Container>
            </PaddedGrid>
          </Grid>
        </Box>
        <Box>
          <Divider />
          <Box mt={4}>
            <Container maxWidth="md">
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <Contact shopDetails={shopDetails} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OpeningHours openingHours={shopDetails.opening_hours} />
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box mt={2}>
            <BookingFooter />
          </Box>
        </Box>
      </Box>
      <BookingSuccess
        open={succesfulBooking}
        onClick={() => navigate(`/shop/${shopDetails.slug}`)}
      />
    </>
  );
};

export default BaseHOC(BookService);
