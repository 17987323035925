import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import PrimaryButton from '../PrimaryButton';
import BarberSelectorStep from './BarberSelectorStep';

import { ccyFormat } from '../../utils';

const ExtrasSelectorStep = ({
  service,
  shopDetails,
  selectedExtraServiceBaseIds,
  setSelectedExtraServiceBaseIds,
  selectedExtraBarberIds,
  setSelectedExtraBarberIds,
  selectedBarberId,
}) => {
  const { formatMessage: f } = useIntl();
  const publicShopDetails = useSelector((state) => state.publicShopDetails);

  useEffect(() => {
    if (selectedBarberId) {
      console.log('hola!');
      setSelectedExtraServiceBaseIds([]);
    }
  }, [selectedBarberId]);

  const getBarbers = (serviceBaseId) =>
    (serviceBaseId &&
      ((publicShopDetails &&
        publicShopDetails.barbers &&
        publicShopDetails.barbers
          .filter((x) => !x.disable_booking)
          .filter(
            (x) =>
              !x.exclude_service_ids ||
              x.exclude_service_ids.indexOf(serviceBaseId) === -1,
          )) ||
        shopDetails.barbers
          .filter((x) => !x.disable_booking)
          .filter(
            (x) =>
              !x.exclude_service_ids ||
              x.exclude_service_ids.indexOf(serviceBaseId) === -1,
          ))) ||
    [];

  const getDisabledBaseIds = () =>
    publicShopDetails.services
      .filter(
        (x) =>
          selectedExtraServiceBaseIds.indexOf(x.base_id) > -1 ||
          x.base_id === service.base_id,
      )
      .map((x) => x.disabled_services_base_ids)
      .flat();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      flex={1}
      m={1}
    >
      {selectedExtraServiceBaseIds.length > 0 && (
        <Box>
          {/* <Box fontWeight="fontWeightBold" mb={1}>
            {f({ id: 'extraBookingSelectExtraBarbers' })}
          </Box> */}
          {selectedExtraServiceBaseIds.map((x, i) => (
            <Box mb={2} key={`selected-service-${x}`}>
              {/* <Box fontWeight="fontWeightBold" mb={1}>
                {shopDetails?.services?.find((y) => y.base_id === x)?.title}
              </Box>
              <BarberSelectorStep
                value={selectedExtraBarberIds[i]}
                onChange={(event) => {
                  const newValues = [...selectedExtraBarberIds];
                  newValues.splice(i, 1, event.target.value);
                  setSelectedExtraBarberIds(newValues);
                }}
                serviceBaseId={x}
                options={getBarbers(x)}
              /> */}
              <Box my={1}>
                <PrimaryButton
                  startIcon={<RemoveIcon />}
                  onClick={(event) => {
                    setSelectedExtraServiceBaseIds([
                      ...selectedExtraServiceBaseIds.filter((y) => y !== x),
                    ]);
                    setSelectedExtraBarberIds([
                      ...selectedExtraBarberIds.filter(
                        (y, i) => selectedExtraServiceBaseIds.indexOf(x) !== i,
                      ),
                    ]);
                  }}
                >
                  {/* {f({ id: 'buttonCancel' })} */}
                  {shopDetails?.services?.find((y) => y.base_id === x)?.title}
                </PrimaryButton>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {service?.previous_services_base_ids
        ?.filter((x) => selectedExtraServiceBaseIds.indexOf(x) < 0)
        .filter((x) => getDisabledBaseIds().indexOf(x) < 0)
        .filter((x) => shopDetails?.services?.find((y) => y.base_id === x))
        .length > 0 && (
        <Box>
          <Typography variant="body2">
            {f({ id: 'extraSreviceBefore' }, { title: service.title })}:
          </Typography>
          {service?.previous_services_base_ids
            ?.filter((x) => selectedExtraServiceBaseIds.indexOf(x) < 0)
            .filter((x) => getDisabledBaseIds().indexOf(x) < 0)
            .filter((x) => shopDetails?.services?.find((y) => y.base_id === x))
            .map((x) => (
              <Box
                m={1}
                key={`previous-service-${x}`}
                sx={{ display: 'inline-block' }}
              >
                <PrimaryButton
                  startIcon={<AddIcon />}
                  onClick={(event) => {
                    setSelectedExtraServiceBaseIds([
                      ...selectedExtraServiceBaseIds,
                      x,
                    ]);
                    setSelectedExtraBarberIds([
                      ...selectedExtraBarberIds,
                      getBarbers(x)?.find((y) => y.id === selectedBarberId)
                        ?.id || -1,
                    ]);
                  }}
                >
                  {shopDetails?.services?.find((y) => y.base_id === x)?.title}
                </PrimaryButton>
              </Box>
            ))}
        </Box>
      )}
      {service?.next_services_base_ids
        ?.filter((x) => selectedExtraServiceBaseIds.indexOf(x) < 0)
        .filter((x) => getDisabledBaseIds().indexOf(x) < 0)
        .filter((x) => shopDetails?.services?.find((y) => y.base_id === x))
        .length > 0 && (
        <Box>
          <Typography variant="body2">
            {f({ id: 'extraSreviceAfter' }, { title: service.title })}:
          </Typography>
          {service?.next_services_base_ids
            ?.filter((x) => selectedExtraServiceBaseIds.indexOf(x) < 0)
            .filter((x) => getDisabledBaseIds().indexOf(x) < 0)
            .filter((x) => shopDetails?.services?.find((y) => y.base_id === x))
            .map((x) => (
              <Box
                m={1}
                key={`next-service-${x}`}
                sx={{ display: 'inline-block' }}
              >
                <PrimaryButton
                  startIcon={<AddIcon />}
                  onClick={(event) => {
                    setSelectedExtraServiceBaseIds([
                      ...selectedExtraServiceBaseIds,
                      x,
                    ]);
                    setSelectedExtraBarberIds([
                      ...selectedExtraBarberIds,
                      getBarbers(x)?.find((y) => y.id === selectedBarberId)
                        ?.id || -1,
                    ]);
                  }}
                >
                  {shopDetails?.services?.find((y) => y.base_id === x)?.title}
                </PrimaryButton>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default ExtrasSelectorStep;
